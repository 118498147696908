import React, {useState} from 'react';
import './App.css';
import TwoPane from './TwoPane'
import idan from './IdanKedarRecommendation.png';
import gabi from './GabiRoismanRecommendation.png';
import mike from './MikeDiazRecommendation.png';
import sheryl from './SherylLunRecommendation.png';
import siva from './SivaKumarRecommendation.png';
import amit from './AmitLInkedInPhoto.jpeg';
import pelican from './pelican.jpg';
import mountains from './yosemiteMountains.jpg';
import milkyWay from './milkyWay.jpg';
import missionChurch from './scuChurch.jpg';
import hummingbird from './Hummingbird.jpg';

const AboutPage = () => {
  return (
    <div style={{backgroundColor: 'rgba(1,1,90,1)'}} >
        <TwoPane t1={
            <img class="neverRemove" src={amit} alt="Amit Nagdev" style={{display: 'inline-block', maxWidth: '50%%', marginLeft: '5%', borderRadius: '50%'}} />
        }
                 t2={
                     <p style={{color: 'white', display: 'inline-block', width: '50%', verticalAlign: 'top'}}>
                         Amit Nagdev is a programmer with two internships of experience. Amit excels in backend programming but is proficient in frontend programming as well. He enjoys programming because every step, no matter how small, is a puzzle that needs to be solved. There is nothing else that he can spend 24 hours on without getting bored. <br/><br/>
                         He has interned at SmugMug (Photo hosting website for professional photogaphers) and Imperva (cyber security company) and excelled at both.
                         <br/><br/>
                         He has a passion for photography and technology and spends his free time either taking photos, playing games with friends, or doing light graphic design work in Adobe Illustrator.
                     </p>

        }

        />
        <br/>
        <br/>
        <h1 style={{textAlign: 'center', color: 'white'}}>These are the photos I'm most proud of taking</h1>
        <div >

            <img class="neverRemove" style={{width: '100%'}} src={mountains} alt="Mountains in Yosemite"/>
            <h3 style={{backgroundColor: 'black', textAlign: 'center', marginTop: 0, color: 'white', marginBottom: '10%', padding: '3%'}}>Yosemite Mountains</h3>

            <img class="neverRemove" style={{width: '100%'}} src={milkyWay} alt="Milky Way in Yosemite after Sunset" />
            <h3 style={{backgroundColor: 'black', textAlign: 'center', marginTop: 0, color: 'white', marginBottom: '10%', padding: '3%'}}>Milky Way just after Sunset in Yosemite</h3>

            <img class="neverRemove" style={{width: '100%'}} src={pelican} alt="San Francisco Brown Pelican" />
            <h3 style={{backgroundColor: 'black', textAlign: 'center', marginTop: 0, color: 'white', marginBottom: '10%', padding: '3%'}}>San Francisco Brown Pelican</h3>

            <img class="neverRemove" style={{width: '100%'}} src={missionChurch} alt="Santa Clara University Church" />
            <h3 style={{backgroundColor: 'black', textAlign: 'center', marginTop: 0, color: 'white', marginBottom: '10%', padding: '3%'}}>Santa Clara University Mission</h3>

            <img class="neverRemove" style={{width: '100%'}} src={hummingbird} alt="Hummingbird Catching a Fly" />
            <h3 style={{backgroundColor: 'black', textAlign: 'center', marginTop: 0, color: 'white', padding: '3%'}}>Hummingbird Catching Fly</h3>

        </div>
    </div>
  );
};

const ResumePage = () => {
    return (
        <div style={{backgroundColor: 'rgba(1,1,90,1)', display: 'flex', flex: 1, flexDirection: 'column'}} >



            <h2 style={{borderRadius: '25% 25% 10% 10%', marginTop: '5%', color: 'white', width: '90%', display: 'inline-block', textAlign: 'center', backgroundColor: 'black', padding: '5%'}}>What Do People Think of Me?</h2>
            <div style={{width: '80%', marginLeft: '10%', backgroundColor: 'rgb(1,1,90)'}} >
                <img src={gabi} alt="" style={{maxWidth: '100%', marginLeft: '5%', marginTop: '2%'}}/>
                <h3 className={'imgReplacement imgReplacementHeader'}>Gabriel Roisman</h3>
                <p className={'imgReplacement imgReplacementParagraph'}>
                    Amit worked in my team as an intern in the summer of 2018.
                    I was impressed by Amit's ability to learn new technologies including java script and security problems. I was also surprised by Amit's ability to understand, analyze, and explain to the rest of the team complex problems such as memory leak in a short time.
                    I would be happy to work again with Amit and I hope he will choose to continue his internship with Imperva in the future.
                </p>
                <img src={idan} alt="" style={{maxWidth: '100%', marginLeft: '5%', marginTop: '2%'}}/>
                <h3 className={'imgReplacement imgReplacementHeader'}>Idan Kedar</h3>
                <p className={'imgReplacement imgReplacementParagraph'}>
                    We had a lot of fun during the summer of 2018.
                    I was in charge of our interns when Amit joined us.
                    Amit is very enthusiastic and eager to learn, and very practical in applying what he learned.
                    More importantly, he saw through a complicated task. Amit's main project, a website attack tool for our testing (built as a service), is not only very useful to us, but during this project we as a team learned some advanced node.js programming.
                    It's been a pleasure having Amit in my team, he'd be a great addition to any team.
                </p>
                <img src={siva} alt="" style={{maxWidth: '100%', marginLeft: '5%', marginTop: '2%'}}/>
                <h3 className={'imgReplacement imgReplacementHeader'}>Siva Kumar</h3>
                <p className={'imgReplacement imgReplacementParagraph'}>
                    Amit was exceptional in understanding the problem statement and developing systems at the backend. He implemented an Authentication server and a client in Java and nodeJS, a crawler in nodeJS and a reverse proxy using Nginx. He showed a strong understanding of asynchronous programming, performance optimization and authentication principles. His debugging skills in various languages are commendable.
                </p>
                <img src={sheryl} alt="" style={{maxWidth: '100%', marginLeft: '5%', marginTop: '2%'}}/>
                <h3 className={'imgReplacement imgReplacementHeader'}>Sheryl Lun</h3>
                <p className={'imgReplacement imgReplacementParagraph'}>
                    Amit was an excellent intern at SmugMug in summer 2019. He worked on my team and I helped him with his summer project. I appreciated that Amit was curious, eager to learn, and a hard worker. I was impressed that he was able to pick up both PHP and React while working here and did a great job using both for his project. I would recommend Amit for any engineering team he's looking to join in the future.
                </p>
                <img src={mike} alt="" style={{maxWidth: '100%', marginLeft: '5%', marginTop: '2%'}}/>
                <h3 className={'imgReplacement imgReplacementHeader'}>Mike Diaz</h3>
                <p className={'imgReplacement imgReplacementParagraph'}>
                    It was a pleasure working with Amit this past summer during his internship with SmugMug. He exhibited a willingness to take on any task that was asked of him and tackled some complex areas of the codebase while always being accepting of feedback and suggestions during code reviews and the in-depth technical discussions we'd get into over lunch. I think we both learned a bunch from each other over the course of a few months and he'd be a solid addition to any engineering team.
                </p>
            </div>
            <h2 style={{borderRadius: '12% 12% 12% 12%', marginTop: '5%', color: 'white', width: '90%', display: 'inline-block', textAlign: 'center', backgroundColor: 'black', padding: '5%'}}>What Have I Done?</h2>
            <div style={{display: 'block', alignSelf: 'start', width: '80%', margin: '10%', marginTop: 0, backgroundColor: 'white', borderRadius: '5%', paddingTop: '5%'}}>
                <h2 style={{padding: '5%', color: 'white', width: '90%', display: 'block', textAlign: 'center', backgroundColor: 'rgba(1,1,90,.8)', borderRadius: '12% 12% 0 0'}}>SmugMug<br/><br/>June - August 2019</h2>
                <TwoPane t1={
                    <div style={{display: 'inline-block', height: '100%', color: 'rgba(1,1,90, 1)', verticalAlign: 'top', width: '25%', paddingLeft: '5%', paddingRight: '5%'}}>
                        <h4 style={{margin: 0, display: 'flex', fontSize: '1.4em'}}>Built Feature for SmugMug.com:</h4>
                        <ol style={{fontSize: '1.2em'}} >

                            <li>Build Page Templates Feature</li>
                            <li>Fix Logo Redirect on Mobile</li>
                            <li>Enhance User Subscription Experience</li>
                        </ol>
                    </div>
                }
                         t2={
                             <div style={{display: 'inline-block', color: 'rgba(1,1,90,0.8)', fontSize: '1.2em', borderColor: 'rgba(1,1,90,0.8)', borderStyle: 'solid', borderRadius: '70px 70px 0 0', borderTopStyle: 'none', borderBottomStyle: 'none', padding: '5%', borderWidth: '10px', verticalAlign: 'top', width: '50%', height: '100%', paddingLeft: '5%', paddingRight: '5%', paddingBottom: '5%'}}>
                                 <p style={{borderStyle: 'solid', borderWidth: '0', borderTopWidth: '5px', padding: '3%'}}>
                                     1 -Build Page Templates Feature
                                     <br/><br/>
                                     Developed Frontend, modified Backend, and worked with Design Team to find the ideal solution that would leave legacy code intact. Frontend used React and JavaScript. The Backend used PHP. The legacy code I needed to work around used the YUI framework.
                                     <br/><br/>
                                     Frontend consisted of a modal that allowed for choosing which template the user wanted to start with.
                                     <br/><br/>
                                     Backend required modification. I was given a page cloning mechanism and needed to adapt it to the existing code without disrupting existing behaviors elsewhere in the site that relied on the same code.
                                     While working on the backend I modified API calls and heavily modified the cloning code's behavior to generate a clone while also using the existing system for populating page data.
                                     <br/><br/>
                                     Due to time constraints it was not possible to create the whole system from scratch, so I worked with the design team to find the optimal way to add the feature to the site without disrupting existing functionality.
                                     The end decision was to add a modal before the existing one and to use data from both in order to make one page from a template. We came to this conclusion after I tested the viability of multiple possible solutions and realized that the aforementioned solution was ideal.
                                     <br/><br/>
                                     The greatest challenge I faced was finding a way to populate the clone made with the new system with data from the older page creation system, but I was able to do so by modifying the backend PHP code. I completed this task in about 5-6 weeks.
                                 </p>
                                 <br/>
                                 <p style={{borderStyle: 'solid', borderWidth: '0', borderTopWidth: '5px', padding: '3%'}}>
                                     2 - Fix Logo Redirect on Mobile
                                     <br/><br/>
                                     Clicking the SmugMug logo on mobile would cause unwanted behavior.
                                     <br/><br/>
                                     I modified the behavior for mobile so that it would no longer redirect to the SmugMug website and leave users unable to return to the app.
                                 </p>
                                 <p style={{borderStyle: 'solid', borderWidth: '0', borderTopWidth: '5px', padding: '3%'}}>
                                     3 - Enhance User Subscription Experience
                                     <br/><br/>
                                     On a specific page in the account settings there were duplicate / redundant elements of the page, such as duplicate subscribe buttons in two locations.
                                     <br/><br/>
                                     I tracked down the YUI code causing the duplication and removed it
                                 </p>
                             </div>
                         }
                         t1w={'55%'}
                         t2w={'40%'}
                />
                <h2 style={{padding: '5%', color: 'white', width: '90%', display: 'block', textAlign: 'center', backgroundColor: 'rgba(1,1,90,.8)', borderRadius: '2% 2% 2% 2%'}}>Imperva<br/><br/>June to September 2018</h2>
                <TwoPane t1={
                    <div style={{display: 'inline-block', height: '100%', color: 'rgba(1,1,90, 1)', verticalAlign: 'top', width: '25%', paddingLeft: '5%', paddingRight: '5%'}}>
                        <h4 style={{margin: 0, display: 'flex', fontSize: '1.4em'}}>Developed Bot Protection Testing Tools:</h4>
                        <ol style={{fontSize: '1.2em'}} >

                            <li>Credential Stuffer</li>
                            <li>Asynchronous Web Scraper</li>
                            <li>NGINX Reverse Proxy</li>
                            <li>NodeJS (JavaScript) Client and Server</li>
                            <li>Java Client and Server</li>
                        </ol>
                    </div>
                }
                         t2={
                             <div style={{display: 'inline-block', color: 'rgba(1,1,90,0.8)', fontSize: '1.2em', borderColor: 'rgba(1,1,90,0.8)', borderStyle: 'solid', borderRadius: '0 0 70px 70px',borderTopStyle: 'none', borderBottomStyle: 'none', padding: '5%', borderWidth: '10px', verticalAlign: 'top', width: '50%', height: '100%', paddingLeft: '5%', paddingRight: '5%', paddingBottom: '5%'}}>
                                 <p style={{borderStyle: 'solid', borderWidth: '0', borderTopWidth: '5px', padding: '3%'}}>
                                     1 - Credential Stuffer
                                     <br/><br/>
                                     Uses NodeJS and Google's Puppeteer Framework.
                                     Attempts to log in to a website using "stolen" credentials
                                     and logs every valid credential it finds to a file.
                                     <br/><br/>
                                     Credential Stuffer was so good at its job that it bypassed modern
                                     Web Application Firewalls (WAF).
                                     <br/><br/>
                                     To achieve this result I had to add features inspired by my Grandfather
                                     such as variable typing cadence (input a range of per character typing
                                     frequency at program launch) in addition to double clicking email fields
                                     on login pages (grandparents think everything needs a double click).
                                     <br/><br/>
                                     One piece I was not able to implement (time constraints) that was NOT necessary for bypassing
                                     modern WAF was to emulate trackpad movements when moving the mouse cursor.
                                     Reasoning being that mouse movements from Puppeteer are too easy to detect
                                     and most users of laptops (especially college students with MacBooks) use a
                                     trackpad. Trackpads are also much easier to make believable than mouse
                                     movements.
                                 </p>
                                 <br/>
                                 <p style={{borderStyle: 'solid', borderWidth: '0', borderTopWidth: '5px', padding: '3%'}}>
                                     2 - Async Web Scraper
                                     <br/><br/>
                                     Uses NodeJS and Google's Puppeteer Framework.
                                     Crawls an entire website given a starting page.
                                     <br/><br/>
                                     Implements a tab pool to avoid memory leak.
                                     <br/><br/>
                                     Tab pool limits the number of active tabs to a number defined at launch.<br/>
                                 </p>
                                 <br/>
                                 <p style={{borderStyle: 'solid', borderWidth: '0', borderTopWidth: '5px', padding: '3%'}}>
                                     3 - NGINX Reverse Proxy
                                     <br/><br/>
                                     Hides GUI and Data Services behind a single user accessible IP.
                                     <br/><br/>
                                     When being redirected to GUI or Data Services, the user does not see a change in domain / IP address in their browser.
                                     <br/><br/>
                                     NGINX is also used for load balancing, though not in this case.
                                 </p>
                                 <br/>
                                 <p style={{borderStyle: 'solid', borderWidth: '0', borderTopWidth: '5px', padding: '3%'}}>
                                     4 - Clients and Servers
                                     <br/><br/>
                                     Built a set of clients and servers in both Java and JavaScript
                                 </p>
                                 <p style={{borderStyle: 'solid', borderWidth: '0', borderTopWidth: '5px', padding: '3%', paddingBottom: '15%'}}>
                                     5 - Solved Memory Leak
                                     <br/><br/>
                                     Discovered memory leak in Async Web Scraper (2), diagnosed the leak, and solved the leak.
                                     <br/><br/>
                                     Diagnosed leak using a heap dump. Solved leak by making orphan strings. JavaScript keeps the parent of a string alive when creating a substring and we were storing many substrings. Using a technique I found on my own I removed the parents and isolated the substring.
                                     <br/><br/>
                                     Presented tech note on memory leak to entire team and advocated for my unique solution.
                                 </p>
                             </div>
                         }
                         t1w={'55%'}
                         t2w={'40%'}
                />

            </div>
        </div>
    );
};


function App() {
    const [PageToRender, setPageToRender] = useState(<ResumePage />)
  return (
      <>
    <div style={{backgroundColor:'rgba(1,1,90,1)'}}>
        <div style={{borderRadius: '0% 0% 12% 12%', backgroundColor: 'black'}}>
            <TwoPane t1={
                <div style={{display: 'inline-block', backgroundColor: 'black', width: '35%', marginLeft: '2%'}}>
                    <h1 style={{color: 'white', margin: 0, display: 'block', textAlign: 'center'}}>AMIT NAGDEV</h1>
                    <div style={{display: 'block', backgroundColor: 'black', width: '100%', marginRight: '20%', marginTop: '1%'}}>
                        <h3 style={{color: 'white', margin: 0, display: 'block', textAlign: 'center'}}>SOFTWARE ENGINEER</h3>
                        <h4 className={'subheader'} style={{color: 'white', margin: 0, display: 'block', textAlign: 'center'}}>With two Internships of Experience</h4>
                    </div>
                </div>
            }
                     t2={
                         <div style={{display: 'inline-block', width: '50%', marginLeft: '3%', backgroundColor: 'black'}}>
                             <nav style={{backgroundColor: 'black'}}>
                                 <button style={{marginLeft: '0%', padding: '2%', width: '92%', backgroundColor: 'rgba(87,85,200,.8)', color: 'white', borderStyle: 'none', borderTopStyle: 'solid', borderColor: 'black', borderWidth: '4px', fontSize: '1.2em'}} onClick={() => {setPageToRender(<AboutPage />)}} >
                                     About Me
                                 </button>
                                 <button style={{marginLeft: '15%', padding: '2%', width: '100%', backgroundColor: 'rgba(87,85,200,.8)', color: 'white', borderStyle: 'none', borderTopStyle:'solid', borderColor: 'black', borderWidth: '7px', fontSize: '1.2em'}} onClick={() => {setPageToRender(<ResumePage />)}} >
                                     Qualifications
                                 </button>
                             </nav>
                         </div>

                     }
                     t1w={'50%'}
                     t2w={"50%"}
            />
        </div>

        {PageToRender}
    </div>
          <p>Contact Info: amit@nagdev.com | 925-321-5583 | LinkedIn: https://www.linkedin.com/in/amit-nagdev-1a57a37a/</p>
    </>
  );
}


export default App;
