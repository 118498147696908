import React from 'react';

const TwoPane = ({t1, t2, t1w, t2w}) => {

    return (
        <div style={{width: '80%', paddingLeft: '10%', paddingRight: '10%'}}>
                {[t1, t2]}
        </div>
    )
};

export default TwoPane;